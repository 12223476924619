import { lazy } from "react";
import { HOME, THANKS, LOGIN, TRY_FREE, TRY_FREE_TWO, TRY_FREE_THREE, MY_ACCOUNT } from "constant";

export const routes = [
    {
        path: HOME,
        component: lazy(() => import("containers/Home/Home")),
    },
    {
        path: THANKS,
        component: lazy(() => import("containers/Thanks/Thanks")),
    },
    {
        path: LOGIN,
        component: lazy(() => import("containers/Login/Login")),
    },
    {
        path: TRY_FREE,
        component: lazy(() => import("containers/TryFree/TryFree")),
    },
    {
        path: TRY_FREE_TWO,
        component: lazy(() => import("containers/TryFree/TryFreeTwo")),
    },
    {
        path: TRY_FREE_THREE,
        component: lazy(() => import("containers/TryFree/TryFreeThree")),
    },
    {
        path: MY_ACCOUNT,
        component: lazy(() => import("containers/MyAccount/MyAccount")),
    }
];
