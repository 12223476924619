import { HttpClient } from 'utils/httpClient';
import { HeaderDataType, HeaderResponseDataType } from './types';
import { getImage } from 'utils';

export const getHeaderInfo = async (): Promise<HeaderDataType> =>
	HttpClient.get<HeaderResponseDataType>('header', {
        'populate[HeroBlock][populate][0]': 'FeatureItem.image',
        'populate[LoginBlock][populate]': '*',
        'populate[Logo][populate]': '*',
        'populate[SocialBlock][populate][0]': 'SocialLink.icon',
        'populate[MenuBlock][populate]': '*'
    }).then((res) => {
		const { data: { attributes } } = res;

        return {
            hero: {
                title: attributes.HeroBlock.title,
                item: attributes.HeroBlock.FeatureItem.map((i) => ({
                    title: i.title,
                    tag: i.tag,
                    imageUrl: getImage(i.image)
                }))
            },
            login: {
                title: attributes.LoginBlock.text,
                imageUrl: getImage(attributes.LoginBlock.image),
                url: attributes.LoginBlock.url
            },
            logo: getImage(attributes.Logo.image),
            socialTitle: attributes.SocialBlock.title,
            socials: attributes.SocialBlock.SocialLink.map((s) => ({
                url: s.url,
                imageUrl: getImage(s.icon),
            })),
            menuTitle: attributes.MenuBlock.title,
            menu: attributes.MenuBlock.Link
        };
	});
