export const ICON = {
    rocket: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="29"
            fill="none"
            viewBox="0 0 28 29"
        >
            <g fill="#fff" clipPath="url(#clip0_2437_1023)">
                <path d="M7.92 23.063c-.298.488-.864.763-1.252.854-.656.122-1.282.183-1.76.58-.149.122-.387.03-.417-.153-.09-.58.06-1.22.328-1.616-.12 0-.209.03-.328.091-.209.092-.417-.091-.388-.304.18-.671.627-1.556 1.313-1.952L4.073 19.19c-2.206.488-3.578 3.171-4.055 5.001a.615.615 0 00.864.702c.239-.122.478-.183.746-.183-.596.915-.984 2.409-.746 3.781.09.458.627.671.985.366C3 27.912 4.46 27.82 5.982 27.515c1.133-.244 2.953-1.25 3.4-2.958-.12-.091-.239-.213-.358-.335l-1.103-1.159zM22.504 15.775C26.977 10.591 28.111 3.15 27.991.893a.886.886 0 00-.268-.61.996.996 0 00-.596-.274c-2.207-.122-9.484 1.006-14.554 5.61l-1.61-.609a4.474 4.474 0 00-5.01 1.342L3.148 9.737c-.388.488-.209 1.22.358 1.433l3.579 1.342a44.685 44.685 0 00-2.088 3.965c-.268.64-.15 1.372.328 1.89l4.712 4.819a1.61 1.61 0 001.85.335 36.142 36.142 0 003.876-2.135l1.312 3.66c.209.58.925.793 1.402.366l3.28-2.867c1.432-1.25 1.969-3.293 1.313-5.123l-.567-1.647zm-1.938-4.086a2.8 2.8 0 01-4.056 0 2.955 2.955 0 010-4.148 2.8 2.8 0 014.056 0c1.133 1.159 1.133 3.02 0 4.148z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2437_1023">
                    <path fill="#fff" d="M0 0H28V29H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    threeDots: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="47"
            height="33"
            fill="none"
            viewBox="0 0 47 33"
        >
            <g fill="#fff" clipPath="url(#clip0_2437_1027)">
                <path d="M45.26 14.747H1.74C.78 14.747 0 15.531 0 16.5c0 .968.779 1.752 1.74 1.752h43.52c.961 0 1.74-.784 1.74-1.752 0-.969-.779-1.753-1.74-1.753zM45.26 29.495H1.74c-.961 0-1.74.786-1.74 1.753C0 32.215.779 33 1.74 33h43.52c.961 0 1.74-.785 1.74-1.752s-.779-1.753-1.74-1.753zM1.74 3.505h43.52c.961 0 1.74-.786 1.74-1.753C47 .785 46.221 0 45.26 0H1.74C.78 0 0 .785 0 1.752s.779 1.753 1.74 1.753z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2437_1027">
                    <path fill="#fff" d="M0 0H47V33H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    times: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
        >
            <path
                fill="#fff"
                d="M20.39 18L35.488 2.9c.683-.683.683-1.707 0-2.388-.683-.683-1.707-.683-2.389 0L18 15.61 2.9.511C2.22-.17 1.195-.17.512.512c-.683.684-.683 1.707 0 2.389l15.1 15.1-15.1 15.099c-.683.682-.683 1.706 0 2.388.34.34.768.511 1.195.511.427 0 .853-.17 1.195-.511l15.1-15.1 15.098 15.1c.341.34.768.511 1.195.511.427 0 .853-.17 1.195-.511.683-.683.683-1.707 0-2.389L20.39 18z"
            ></path>
        </svg>
    ),
    rocketTwo: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="none"
            viewBox="0 0 26 26"
        >
            <path
                fill="#1F1F45"
                d="M19.647 15.775C24.12 10.591 25.254 3.15 25.134.893a.886.886 0 00-.268-.61.996.996 0 00-.597-.274c-2.206-.122-9.483 1.006-14.553 5.61l-1.61-.609a4.474 4.474 0 00-5.01 1.342L.291 9.737c-.388.488-.209 1.22.358 1.433l3.578 1.342a44.687 44.687 0 00-2.087 3.965c-.269.64-.15 1.372.328 1.89l4.712 4.819a1.61 1.61 0 001.849.335 36.143 36.143 0 003.877-2.135l1.312 3.66c.209.58.925.793 1.402.366l3.28-2.867c1.432-1.25 1.968-3.293 1.312-5.123l-.566-1.647zm-1.939-4.086a2.8 2.8 0 01-4.056 0 2.955 2.955 0 010-4.148 2.8 2.8 0 014.056 0c1.134 1.159 1.134 3.02 0 4.148z"
            ></path>
        </svg>
    ),
    download: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            fill="none"
            viewBox="0 0 18 19"
        >
            <g fill="#EFA" clipPath="url(#clip0_1_27720)">
                <path d="M9 0c-.318 0-.624.13-.849.359a1.24 1.24 0 00-.351.867V9.91L6.3 8.378a1.225 1.225 0 00-1.7-.104 1.237 1.237 0 00-.398.879c-.01.336.117.66.35.899l2.577 2.632a2.62 2.62 0 001.873.793 2.62 2.62 0 001.874-.793l2.526-2.57a1.289 1.289 0 00.1-1.738c-.222-.25-.532-.397-.861-.407-.33-.009-.647.12-.88.358L10.2 9.91V1.226a1.24 1.24 0 00-.352-.867A1.19 1.19 0 009 0z"></path>
                <path d="M18 13.484c0-.438-.229-.843-.6-1.062a1.178 1.178 0 00-1.2 0 1.23 1.23 0 00-.6 1.062v1.838c0 .325-.126.637-.351.867-.225.23-.53.36-.849.36H3.6c-.318 0-.624-.13-.849-.36a1.24 1.24 0 01-.351-.867v-1.838c0-.438-.229-.843-.6-1.062a1.178 1.178 0 00-1.2 0 1.23 1.23 0 00-.6 1.062v1.838c0 .976.38 1.91 1.054 2.6A3.562 3.562 0 003.6 19h10.8c.955 0 1.87-.388 2.546-1.077a3.716 3.716 0 001.054-2.6v-1.84z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_27720">
                    <path fill="#fff" d="M0 0H18V19H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    folder: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23"
            fill="none"
            viewBox="0 0 24 23"
        >
            <path
                fill="#1F1F45"
                fillRule="evenodd"
                d="M0 22.115v.011a.913.913 0 00.121.443.878.878 0 00.296.306l.003.002A.836.836 0 00.843 23h15.854c.56 0 1.084-.281 1.404-.754l4.8-7.077c.366-.541.412-1.247.116-1.833a1.708 1.708 0 00-1.52-.951H6.485c-.602 0-1.16.326-1.47.858l-3.3 5.679V4.423h5.47l1.24 2.56c.29.6.883.979 1.533.979h6.327c.473 0 .857-.397.857-.885s-.384-.885-.857-.885H9.958l-1.24-2.56c-.29-.6-.884-.978-1.534-.978h-5.47C.767 2.654 0 3.446 0 4.424v17.691zm21.497-7.961l-4.8 7.077H2.37l4.114-7.077h15.012zm-1.782-7.962l-1.029-1.415a.84.84 0 00-1.2-.177.904.904 0 00-.171 1.238s1.123 1.547 1.886 2.595c.323.446.831.708 1.37.708.54 0 1.049-.262 1.372-.708l1.886-2.595a.904.904 0 00-.171-1.238.84.84 0 00-1.2.177l-1.029 1.415V.885c0-.489-.384-.885-.857-.885s-.857.396-.857.885v5.307z"
                clipRule="evenodd"
            ></path>
        </svg>
    ),
    folderTwo: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            fill="none"
            viewBox="0 0 18 17"
        >
            <path
                fill="#1F1F45"
                fillRule="evenodd"
                d="M0 16.346v.008a.654.654 0 00.313.553l.002.002c.093.056.201.09.317.091h11.89c.42 0 .814-.208 1.054-.558l3.6-5.23c.274-.4.308-.922.087-1.355a1.283 1.283 0 00-1.14-.703H4.863c-.451 0-.87.241-1.103.634l-2.475 4.197V3.27h4.102l.93 1.893c.218.443.663.722 1.15.722h4.746a.649.649 0 00.643-.653.649.649 0 00-.643-.654H7.468l-.93-1.893a1.283 1.283 0 00-1.15-.723H1.286C.576 1.962 0 2.547 0 3.27v13.077zm16.122-5.885l-3.6 5.231H1.778l3.086-5.23h11.258zm-1.336-5.884l-.771-1.046a.636.636 0 00-.9-.131.662.662 0 00-.13.915L14.4 6.233c.243.33.624.523 1.029.523.405 0 .786-.193 1.028-.523l1.415-1.918a.662.662 0 00-.129-.915.636.636 0 00-.9.13l-.771 1.047V.654A.649.649 0 0015.429 0a.649.649 0 00-.643.654v3.923z"
                clipRule="evenodd"
                opacity="0.2"
            ></path>
        </svg>
    ),
    folderThree: (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 16.3458V16.3536C0.00146937 16.4732 0.0348975 16.5853 0.0907335 16.6813C0.145835 16.7755 0.223344 16.852 0.312608 16.907L0.315179 16.9085C0.408484 16.9645 0.516483 16.9981 0.632196 17H12.5223C12.9422 17 13.3356 16.7919 13.5759 16.4422C14.1886 15.5515 16.119 12.7467 17.1758 11.2115C17.4502 10.8118 17.4844 10.2902 17.2625 9.85717C17.0414 9.42489 16.6017 9.15364 16.1223 9.15364H4.86398C4.41251 9.15364 3.99411 9.395 3.76121 9.78842L1.2857 13.9853V3.26916H5.38817L6.31828 5.16153C6.53612 5.60502 6.98097 5.88449 7.46843 5.88449H12.2138C12.5686 5.88449 12.8566 5.59157 12.8566 5.23065C12.8566 4.86974 12.5686 4.57682 12.2138 4.57682H7.46843L6.53832 2.68445C6.32049 2.24096 5.87564 1.9615 5.38817 1.9615H1.2857C0.575258 1.9615 0 2.54695 0 3.26916V16.3458ZM16.1223 10.4613L12.5223 15.692H1.7783L4.86398 10.4613H16.1223ZM14.7859 4.57682L14.0145 3.53069C13.8018 3.24188 13.3984 3.1836 13.1145 3.39993C12.8305 3.61625 12.7732 4.02648 12.9859 4.31529C12.9859 4.31529 13.8286 5.45819 14.4002 6.23307C14.643 6.5626 15.0239 6.75614 15.4287 6.75614C15.8335 6.75614 16.2145 6.5626 16.4573 6.23307C17.0289 5.45819 17.8716 4.31529 17.8716 4.31529C18.0842 4.02648 18.0269 3.61625 17.743 3.39993C17.459 3.1836 17.0557 3.24188 16.843 3.53069L16.0716 4.57682V0.653832C16.0716 0.292917 15.7836 0 15.4287 0C15.0739 0 14.7859 0.292917 14.7859 0.653832V4.57682Z"
                fill="#EEFFAA"
            />
        </svg>
    ),
    calendar: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="none"
            viewBox="0 0 23 23"
        >
            <g fill="#1F1F45" clipPath="url(#clip0_1_28653)">
                <path d="M18.577 2.36h-.295V.884a.886.886 0 00-1.769 0v1.474h-4.128V.885a.886.886 0 00-1.77 0v1.474H6.487V.885a.886.886 0 00-1.77 0v1.474h-.294A4.43 4.43 0 000 6.782V18.97A4.056 4.056 0 004.07 23h14.86A4.057 4.057 0 0023 18.97V6.782a4.429 4.429 0 00-4.423-4.423zM4.423 21.23a2.656 2.656 0 01-2.654-2.654v-8.551h17.987a.886.886 0 000-1.77H1.77V6.782A2.655 2.655 0 014.423 4.13h.295v1.474a.886.886 0 001.769 0V4.129h4.128v1.474a.886.886 0 001.77 0V4.129h4.128v1.474a.886.886 0 001.77 0V4.129h.294a2.655 2.655 0 012.654 2.653v11.795a2.655 2.655 0 01-2.654 2.654H4.423z"></path>
                <path d="M10.32 12.68a1.18 1.18 0 11-2.359 0 1.18 1.18 0 012.36 0zM5.602 12.68a1.18 1.18 0 11-2.358 0 1.18 1.18 0 012.358 0zM19.756 12.68a1.18 1.18 0 11-2.36 0 1.18 1.18 0 012.36 0zM15.039 12.68a1.18 1.18 0 11-2.36 0 1.18 1.18 0 012.36 0zM10.32 17.399a1.18 1.18 0 11-2.358 0 1.18 1.18 0 012.359 0zM5.602 17.399a1.179 1.179 0 11-2.357 0 1.179 1.179 0 012.357 0zM19.756 17.399a1.18 1.18 0 11-2.358 0 1.18 1.18 0 012.358 0zM15.039 17.399a1.18 1.18 0 11-2.36 0 1.18 1.18 0 012.36 0z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_28653">
                    <path fill="#fff" d="M0 0H23V23H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    arrowRight: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="23"
            fill="none"
            viewBox="0 0 13 23"
        >
            <path
                fill="#1F1F45"
                d="M13 11.5l-.893-.975L2.451 0 0 1.95l8.763 9.55L0 21.05 2.451 23l9.656-10.525L13 11.5z"
            ></path>
        </svg>
    ),
    fullName: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            fill="none"
            viewBox="0 0 13 13"
        >
            <g fill="#EFA" clipPath="url(#clip0_999_3194)">
                <path d="M4.982 5.517a.76.76 0 11-1.52-.001.76.76 0 011.52.001zM9.532 5.517a.76.76 0 11-1.52-.001.76.76 0 011.52.001z"></path>
                <path d="M.382 12.61a1.303 1.303 0 001.374.299l1.734-.65a6.501 6.501 0 009.495-6.245A6.502 6.502 0 007.008.02 6.498 6.498 0 00.175 5.015a6.519 6.519 0 00.558 4.48l-.648 1.733a1.3 1.3 0 00.297 1.384v-.003zM1.3 6.491a5.199 5.199 0 1110.005 1.99 5.198 5.198 0 01-7.684 2.345l-2.32.866.865-2.321a5.179 5.179 0 01-.866-2.88z"></path>
                <path d="M6.43 9.741a1.589 1.589 0 001.732-1.582c0-.216-.177-.794-.397-.794H5.383c-.216 0-.377.51-.395.755A1.638 1.638 0 006.43 9.743v-.002z"></path>
            </g>
            <defs>
                <clipPath id="clip0_999_3194">
                    <path fill="#fff" d="M0 0H13V13H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    email: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <path
                fill="#EFA"
                d="M2 12h8c.53 0 1.04-.211 1.413-.587.376-.375.587-.883.587-1.413V5.2c0-.318-.128-.624-.352-.849L8.214.916a3.132 3.132 0 00-4.426 0L.352 4.35C.128 4.576 0 4.881 0 5.2V10c0 .531.211 1.04.587 1.414C.962 11.79 1.47 12 2 12zm8-1.2H2a.8.8 0 01-.8-.8V5.898l3 1.801a3.487 3.487 0 003.59 0l3-1.8v4.099a.799.799 0 01-.79.8v.001zM4.634 1.763a1.932 1.932 0 012.73 0l3 3L7.176 6.67a2.326 2.326 0 01-2.354 0l-3.184-1.91 2.996-2.995v-.002z"
            ></path>
        </svg>
    ),
    enterprise: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            fill="none"
            viewBox="0 0 12 13"
        >
            <g clipPath="url(#clip0_1_27702)">
                <path
                    fill="#EFA"
                    d="M12 6.498a2.39 2.39 0 00-.667-1.656 2.344 2.344 0 00-1.623-.718 5.147 5.147 0 00-4.567-2.808c-.156 0-.31 0-.463.024A2.366 2.366 0 003.203.09a2.343 2.343 0 00-1.906.28C.73.728.343 1.32.233 1.984c-.11.668.067 1.35.49 1.877a5.164 5.164 0 000 5.273 2.4 2.4 0 00-.493 1.88c.11.668.497 1.26 1.067 1.619.57.362 1.263.463 1.91.275a2.356 2.356 0 001.473-1.253c.153.014.307.024.463.024A5.148 5.148 0 009.71 8.872a2.344 2.344 0 001.623-.718A2.39 2.39 0 0012 6.498zM2.57 1.316c.283 0 .557.114.757.316a1.085 1.085 0 010 1.525 1.068 1.068 0 01-1.514 0 1.085 1.085 0 010-1.525c.2-.202.474-.316.757-.316zm0 10.36c-.283 0-.557-.114-.757-.315a1.085 1.085 0 010-1.525 1.068 1.068 0 011.514 0 1.085 1.085 0 010 1.525c-.2.201-.474.316-.757.316zm2.57-1.296h-.223A2.374 2.374 0 003.83 8.6a2.336 2.336 0 00-2.063-.225 3.874 3.874 0 010-3.744c.686.252 1.45.164 2.066-.228a2.372 2.372 0 001.08-1.79h.227c1.34 0 2.583.705 3.283 1.857A2.376 2.376 0 007.29 6.498c0 .83.43 1.599 1.133 2.029a3.854 3.854 0 01-3.283 1.857v-.004zm4.5-2.807c-.283 0-.557-.114-.757-.316a1.085 1.085 0 010-1.525 1.068 1.068 0 011.514 0 1.085 1.085 0 010 1.525c-.2.202-.474.316-.757.316z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_27702">
                    <path fill="#fff" d="M0 0H12V13H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    telephone: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
        >
            <g fill="#EFA" clipPath="url(#clip0_1_27706)">
                <path d="M12.419 8.13H10.16a1.581 1.581 0 00-1.58 1.58v2.836c-1.05.134-2.112.134-3.162 0V9.71a1.581 1.581 0 00-1.58-1.58H1.58A1.58 1.58 0 000 9.71v.226a3.35 3.35 0 002.033 3.102c1.575.649 3.264.976 4.967.962a12.784 12.784 0 004.968-.966A3.35 3.35 0 0014 9.935V9.71a1.582 1.582 0 00-1.58-1.581h-.001zm-8.355 4.159a10.384 10.384 0 01-1.506-.502 1.993 1.993 0 01-1.203-1.851V9.71c0-.125.101-.226.226-.226h2.258a.226.226 0 01.226.226v2.579zm8.58-2.353a2.005 2.005 0 01-1.202 1.856c-.49.204-.993.37-1.506.497V9.71c0-.124.1-.225.225-.225h2.258a.225.225 0 01.226.225v.226zM4.604 4.605l-.478.479.957.957.479-.479a2.031 2.031 0 012.874 0l.478.479.958-.957-.48-.479a3.386 3.386 0 00-4.788 0z"></path>
                <path d="M3.008 3.008a5.644 5.644 0 017.982 0l.478.478.95-.957-.478-.479a6.998 6.998 0 00-9.899 0l-.46.479.957.957.47-.478z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_27706">
                    <path fill="#fff" d="M0 0H14V14H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
    edit: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <g clipPath="url(#clip0_999_3178)" opacity="0.35">
                <path
                    fill="#EFA"
                    d="M9.002 0a2.976 2.976 0 00-2.118.877L1.453 6.31A4.922 4.922 0 000 9.817V12h2.183a4.923 4.923 0 003.504-1.453l5.436-5.431a3 3 0 00-.002-4.238A2.998 2.998 0 009.002 0zm1.012 4.007L4.584 9.44a3.371 3.371 0 01-2.4.994h-.619v-.618c-.002-.9.356-1.764.994-2.398l5.434-5.434a1.432 1.432 0 012.024 2.025l-.003-.003z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_999_3178">
                    <path fill="#fff" d="M0 0H12V12H0z"></path>
                </clipPath>
            </defs>
        </svg>
    ),
};
